import React from "react";
import "./Navbar.css";

const Navbar = () => {
  return (
    <nav className="root">
      <h1 className="logo">
        Krish-e Mart
      </h1>
    </nav>
  );
};

export default Navbar;