import React from 'react';
import {useLocation} from 'react-router-dom';

const EmbedScreen = () => {
    const location = useLocation();
  return (
    <div>
        <iframe
        src={location.state.url}
        frameborder="0"
        width="100%"
        height="900"
        title="metabase"
        allowtransparency></iframe>
    </div>
      
  )
}

export default EmbedScreen