import { takeLatest, call, put } from "redux-saga/effects";
import { loginFailure, loginSuccess } from "../action/loginAction";
import { returnErrors, clearErrors } from "../action/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncLoginSaga(action) {
  try {
    yield put(clearErrors());
    let userData = yield call(_api.getUser, {
      requestUser: action.payload.requestUser,
    });
    yield put(loginSuccess({ userData }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(loginFailure({ error: e }));
  }
}
export default function* loginSaga() {
  yield takeLatest(types.LOGIN_REQUEST, asyncLoginSaga);
}