import * as types from "../types";

export const loginRequest = ({ requestUser }) => ({
  type: types.LOGIN_REQUEST,
  payload: {
    requestUser,
  },
});

export const loginSuccess = ({ userData }) => ({
  type: types.LOGIN_SUCCESS,
  payload: {
    userData,
  },
});

export const loginFailure = ({ error }) => ({
  type: types.LOGIN_FAILURE,
  payload: {
    error: error.message,
  },
});

export const loginReset = () => ({
  type: types.LOGIN_RESET,
});