import React, { useState } from 'react';
import './Tabs.css';
import {connect} from 'react-redux';

const Tabs = ({tabsData, ...props}) => {

    const [currentTab, setCurrentTab] = useState('1');

    const handleTabClick = (e) => {
        setCurrentTab(e.target.id);
    }

    return (
        <div className='container'>
            <div className='tabs'>
                {tabsData["data"]["tabs"].map((tab, i) =>
                    <button key={i} id={tab.id} disabled={currentTab === `${tab.id}`} onClick={(handleTabClick)}>{tab.title}</button>
                )}
            </div>
            <div className='content'>
                {tabsData["data"]["tabs"].map((tab, i) =>
                    <div key={i}>
                        <div className={currentTab === `${tab.id}` ? "tabsIframe":"hideTabsIframe"}>
                            <iframe
                                src={tab.url}
                                frameborder="0"
                                width="100%"
                                height="100%"
                                title="metabase"
                                allowtransparency="false"></iframe>    
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

const mapStateToProps=(state) => ({
    tabsData: state.loginReducer.userData,
})

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps,mapDispatchToProps)(Tabs);