import { useState, useEffect } from "react";
import { martBase } from "../config";

const useMartFetch = ({
  tableName,
  fields,
  filterByFormula = "",
  maxRecords = 1000,
  dependencyState=null
}) => {
  const [data, setData] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setFetching(true);
    martBase(tableName)
      .select({
        maxRecords,
        view: "Grid view",
        fields,
        filterByFormula,
      })
      .eachPage(
        function page(records, _fetchNextPage) {
          let recordsData = records.map((item) => {
            return { ...item.fields };
          });

          setData(recordsData);

          setError(false);
          setFetching(false);
        },
        function done(err) {
          setError(false);
          setData(null);
          setFetching(false);
        }
      );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependencyState]);

  return [data, fetching, error];
};

export default useMartFetch;