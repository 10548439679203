import React, {useState, useEffect} from 'react';
import { Navbar } from '../../components/agrishop';
import useMartFetch from '../../utils/useMartFetch';
import './AgriShop.css';
import Swal from 'sweetalert2';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const AgriShop = () => {
  const [selectCategory, setSelectCategory] = useState("");
  const [selectSubCategory, setSelectSubCategory] = useState("");
  const [selectBrand, setSelectBrand] = useState("");
  const [selectVariety, setSelectVariety] = useState("");
  const [category,setCategory]=useState();
  const [subCategory,setSubCategory]=useState();
  const [brand,setBrand]=useState();
  const [variety,setVariety]=useState();
  const [showData,setShowData]=useState();
  const [updateTable,setUpdateTable]=useState(false);

  const [martData] = useMartFetch({
    tableName: "Krishe-Mart",
    fields: [
      `Product Code`,
      `Product Name`,
      "Product Image",
      "Brand Name",
      "Product Category",
      "Sub Category",
      `Crop Name (only for seeds)`,
      `Variety / Techincal name`,
      `Pack Size`,
      "Case Size",
      "Selling Price",
      "MRP",
      "UOM",
      "Discount (%)"
    ],
    maxRecords: 500,
    filterByFormula: "",
  });

  useEffect(()=>{
    if(martData){
      let category=[];
      for(let i=0;i<martData.length;i++){
        category.push(martData[i]["Product Category"]);
      }
      let unqiueTypes=[...new Set(category)];
      setCategory(unqiueTypes);
    }
  },[martData]);

  useEffect(()=>{
    if(showData){
      setUpdateTable(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[showData])

  useEffect(()=>{
    if(selectCategory){
      let subCategory=[];
      for(let i=0;i<martData.length;i++){
        if(selectCategory === martData[i]["Product Category"]){
          subCategory.push(martData[i]["Sub Category"]);
        }
      }
      let unqiueTypes=[...new Set(subCategory)];
      setSubCategory(unqiueTypes);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectCategory]);

  useEffect(()=>{
    if(selectCategory && selectSubCategory){
      let brands=[];
      for(let i=0;i<martData.length;i++){
        if(selectCategory === martData[i]["Product Category"] && selectSubCategory === martData[i]["Sub Category"]){
          brands.push(martData[i]["Brand Name"]);
        }
      }
      let unqiueTypes=[...new Set(brands)];
      setBrand(unqiueTypes);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectCategory,selectSubCategory]);

  useEffect(()=>{
    if(selectCategory && selectSubCategory && selectBrand){
      let varieties=[];
      for(let i=0;i<martData.length;i++){
        if(selectCategory === martData[i]["Product Category"] && selectSubCategory === martData[i]["Sub Category"] 
        && selectBrand === martData[i]["Brand Name"]){
          varieties.push(martData[i]["Variety / Techincal name"]);
        }
      }
      let unqiueTypes=[...new Set(varieties)];
      setVariety(unqiueTypes);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectCategory,selectSubCategory,selectBrand]);

  const handleChange = (event) => {
    console.log(event.target.value);
    const {
      target: { value },
    } = event;
    setSelectCategory(value);
  };

  const handleSubCategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectSubCategory(value);
  };

  const handleBrandChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectBrand(value);
  };

  const handleVarietyChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectVariety(value);
  };

  const handleResults=()=>{
    if(selectCategory === "" && window.innerWidth < 500){
        Swal.fire({
            icon:"info",
            title: 'Select Category',
          })
    }else if(selectCategory === "" && window.innerWidth > 500){
        return (
        <div style={{width:"300px"}}>
            {Swal.fire({
            icon:"info",
            title: 'Select Category',
          })}
        </div>)
    }
    else{
        setUpdateTable(true);
    if(selectCategory && selectSubCategory && selectBrand && selectVariety){
      let filterData = martData.filter(function (el)
    {
      return el["Brand Name"] === selectBrand &&
         el["Product Category"] === selectCategory &&
         el["Sub Category"] === selectSubCategory && el["Variety / Techincal name"] === selectVariety;
    }
    );
    setShowData(filterData);
    }else if(selectCategory && selectSubCategory && selectBrand){
      let filterData = martData.filter(function (el)
    {
      return el["Brand Name"] === selectBrand &&
         el["Product Category"] === selectCategory &&
         el["Sub Category"] === selectSubCategory;
    }
    );
    setShowData(filterData);
    }else if(selectCategory && selectSubCategory){
      let filterData = martData.filter(function (el)
    {
      return el["Product Category"] === selectCategory &&
         el["Sub Category"] === selectSubCategory;
    }
    );
    setShowData(filterData);
    }else{
      setSelectSubCategory("");
      setSelectBrand("");
      setSelectVariety("");
      let filterData = martData.filter(function (el)
    {
      return el["Product Category"] === selectCategory
    }
    );
    setShowData(filterData);
    }
    }
  }

  const handleReset=()=>{
    setSelectCategory("");
    setSelectSubCategory("");
    setSelectBrand("");
    setSelectVariety("");
    setSubCategory();
    setBrand();
    setVariety();
    setShowData();
  }

  return (
    <>
        <Navbar/>
        <div className='rootAgri'>
          <div style={{width:"350px"}}>
          <h3 className='inputHeader'>Category</h3>
          <select value={selectCategory} onChange={handleChange}>
            <option value="">Category</option>
            {category && category.map((name,index) => (
                <option value={name} key={index}>{name}</option>
                ))}
          </select>
          </div>
          <div style={{width:"350px"}}>
          <h3 className='inputHeader'>Sub Category</h3>
          <select value={selectSubCategory} onChange={handleSubCategoryChange}>
            <option value="">Sub Category</option>
            {subCategory && subCategory.map((name,index) => (
                <option value={name} key={index}>{name}</option>
                ))}
          </select>
          </div>
          <div style={{width:"350px"}}>
          <h3 className='inputHeader'>Brand Name</h3>
          <select value={selectBrand} onChange={handleBrandChange}>
            <option value="">Brand</option>
            {brand && brand.map((name,index) => (
                <option value={name} key={index}>{name}</option>
                ))}
          </select>
          </div>
          <div style={{width:"350px"}}>
          <h3 className='inputHeader'>Variety</h3>
          <select value={selectVariety} onChange={handleVarietyChange}>
            <option value="">Variety</option>
            {variety && variety.map((name,index) => (
                <option value={name} key={index}>{name}</option>
                ))}
          </select>
          </div>
          <div className='buttonContainer'>
            <div onClick={handleResults} className='button'>SHOW RESULTS</div>
            <div onClick={handleReset} className='button resetButton'>RESET</div>
          </div>
        </div>
          {!updateTable ? (
            <>
              {showData ? (
                <div style={{padding:"15px"}}>
                <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align='center'>Product Code</TableCell>
                      <TableCell align="center">Product Name</TableCell>
                      <TableCell align="center">Product Image</TableCell>
                      <TableCell align="center">Brand Name</TableCell>
                      <TableCell align="center">Product Category</TableCell>
                      <TableCell align="center">Sub Category</TableCell>
                      <TableCell align="center">Crop Name (only for seeds)</TableCell>
                      <TableCell align="center">Variety</TableCell>
                      <TableCell align="center">Pack Size</TableCell>
                      <TableCell align="center">Case Size</TableCell>
                      <TableCell align="center">Selling Price</TableCell>
                      <TableCell align="center">MRP</TableCell>
                      <TableCell align="center">UOM</TableCell>
                      <TableCell align="center">Discount (%)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {showData.map((row) => (
                      <TableRow>
                        <TableCell component="th" scope="row">{row["Product Code"]}
                        </TableCell>
                        <TableCell align="center">{row["Product Name"]}</TableCell>
                        <TableCell align="center"><img src={row["Product Image"]} alt="seed" width="80px" height="80px"/></TableCell>
                        <TableCell align="center">{row["Brand Name"]}</TableCell>
                        <TableCell align="center">{row["Product Category"]}</TableCell>
                        <TableCell align="center">{row["Sub Category"]}</TableCell>
                        <TableCell align="center">{row["Crop Name (only for seeds)"]}</TableCell>
                        <TableCell align="center">{row["Variety "]}</TableCell>
                        <TableCell align="center">{row["Pack Size"]}</TableCell>
                        <TableCell align="center">{row["Case Size"]}</TableCell>
                        <TableCell align="center">{row["Selling Price"]}</TableCell>
                        <TableCell align="center">{row["MRP"]}</TableCell>
                        <TableCell align="center">{row["UOM"]}</TableCell>
                        <TableCell align="center">{row["Discount (%)"]}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>  
              ) : ("")}
            </>
          ) : (
            <div style={{ padding: "5em" }}>
              Loading
            </div>
          )}
    </>
  )
}

export default AgriShop