import React, {useEffect} from "react";
import './NewTab.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import axios from "axios";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";

const NewTab = ({ ...props }) => {
  const [type,setType]=React.useState("password");
  const [icon,setIcon]=React.useState(<VisibilityOffIcon/>);
  const [data,setData]=React.useState([]);
  const [inputPassword, setInputPassword] =React.useState("");
  const [show,setShow]=React.useState(false);
  const [finalURL,setFinalURL]=React.useState();

  const navigate = useNavigate();

  const handleToggle=()=>{
    if(type === 'password'){
      setIcon(<VisibilityIcon/>);
      setType("text");
    }else{
      setIcon(<VisibilityOffIcon/>);
      setType("password");
    }
  }

  const getAllData=()=>{
    axios.get("https://journeymanager.blob.core.windows.net/dashboard-configs/access-map.json")
      .then((response) => {
        const data=response.data;
        setData(data);
        console.log(data);
      })
      .catch(error => console.log(`${error}`));
  }

  useEffect(()=>{
    getAllData();
  },[]);

  const handleSubmit=()=>{
    const passwordList = Object.keys(data);
    if(passwordList.includes(inputPassword)){
      setFinalURL(data[`${inputPassword}`][0]);
      setShow(true);
      navigate('/metabase',{state:{url:data[`${inputPassword}`][0]}});
    }else{
      console.log("false");
      Swal.fire({
        icon:"error",
        title: 'Please Enter Correct Password',
      })
    }
  }

  return (
    <div className="wrapper">
      <h2 className="heading">Enter Password</h2>
      <div className="input-field">
        <input 
          type={type} 
          value={inputPassword}
          placeholder="Password"
          onChange={(e) => setInputPassword(e.target.value)}/>
        <span onClick={handleToggle}>{icon}</span>
      </div>
      <div onClick={handleSubmit} className="newTabButton">SUBMIT</div>
      {show && (
        <iframe
        src={finalURL}
        frameborder="0"
        width="90%"
        height="600"
        title="metabase"
        allowtransparency></iframe>
      )}
    </div>
  );
};


export default NewTab;