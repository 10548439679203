import React from 'react';
import './EmbedScreenCheck.css';
import Tabs from '../../components/Tabs';
import { useNavigate } from "react-router-dom";
import Logout from '../../assets/logout.png';
import Logo from '../../assets/Logo.svg';
import {connect} from 'react-redux';
import { loginReset } from '../../store/action/loginAction';

const EmbedScreenCheck = ({...props}) => {
    const navigate = useNavigate();

    const handleLogout=()=>{
      props.loginResetUser();
      navigate('/dashboards');
    }

  return (
    <>
        <div className='navbar'>
          <div className='navbar-logo'>{props.icon && props.icon["data"]["dashboard_logo"] ? <img src={props.icon["data"]["dashboard_logo"]} alt="custom-logo"/>:
          <img src={Logo} alt="carnot-logo"/>}</div>
          <div className='logout' onClick={handleLogout}>
            <p>Logout</p>
            <img src={Logout} alt="logout"/>
          </div>
        </div>
        <Tabs/>
        <div className='powered'><p>Powered By</p> <img src={Logo} alt="logo" width="60px" height="60px"/></div>
    </>
      
  )
}

const mapStateToProps=(state) => ({
  icon: state.loginReducer.userData,
})

const mapDispatchToProps = (dispatch) => ({
  loginResetUser: () =>
    dispatch(loginReset()),
});

export default connect(mapStateToProps,mapDispatchToProps)(EmbedScreenCheck);