import { Navigate, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';

const ProtectedRoute = ({isLogged}) => {  
  return isLogged && isLogged["status"] ? <Outlet/>:<Navigate to="/login" />
}

const mapStateToProps=(state) => ({
  isLogged: state.loginReducer.userData,
})

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps,mapDispatchToProps)(ProtectedRoute)